<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-img
            src="@/assets/images/logos/devias.png"
            class="logoPoliticas"
          />
          <v-card-title class="title-legales-card">
            Políticas generales de almacén
          </v-card-title>
          <v-card-text class="card-text-legales">
            <p class="pPolitica">Favor de considerar las siguientes medidas básicas de seguridad:</p>
            <ul>
              <li>1. Llegar 20 minutos antes de su cita, sin estar bajo los efectos del alcohol o drogas</li>
              <li>2. No se recibirán unidades sin previa cita, o comunicado, con un mínimo de 48 horas</li>
              <li>3. No se permite uso de gorras, lentes obscuros y celular dentro de las instalaciones</li>
              <li>4. Velocidad máxima 10 km/h</li>
              <li>5. Respetar los señalamientos</li>
              <li>6. No usar lenguaje obsceno</li>
              <li>7. No portar e ingerir bebidas embriagantes y/o drogas dentro del patio de maniobra y almacén</li>
              <li>8. Fumar sólo en áreas permitidas</li>
              <li>9. Hacer buen uso de los sanitarios</li>
              <li>10. Siga las indicaciones, y puntos de seguridad, de la empresa</li>
              <li>11. La empresa no cuenta con maniobristas o representantes </li>
              <li>12. En caso de ingresar artículos personales (patines, playo, etcétera), deberá registrar su ingreso y salida</li>
            </ul>
            <p class="notas-extra-card-legales">Cualquier omisión a este reglamento, será motivo de una sanción.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.logoPoliticas{
  width: 35%;
  position: relative;
  left: 30%;
}
.pPolitica{
  text-align: justify;
}
.title-legales-card{
  text-transform: uppercase;
}
.notas-extra-card-legales{
  margin-top: 20px;
  font-style: italic;
  font-weight: 900;
}
.card-text-legales{
  margin: 0px auto;
  width: 90%;
}
</style>
