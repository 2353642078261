<template>
  <div>
    <v-row>
      <v-col cols="12">
        <politicas></politicas>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import politicas from './Card-politicas.vue'

export default {
  components: {
    politicas,
  },
}
</script>

<style>

</style>
